<template>
  <div>
    <Button
      v-if="workspaceMemberIntegrationsLoaded"
      :text="triggerText"
      type="white"
      @click="triggerFunction"
    >
      <template #right>
        <img
          :src="integrationObject.image"
          alt=""
          class="calendar-connect-trigger-img"
        />
      </template>
    </Button>
    <b-modal
      :active="modalVisible"
      :can-cancel="['escape', 'outside']"
      @close="() => (step ? cancelTemplateSelect : close)()"
    >
      <div class="calendar-connect" :class="{ restricted: !step }">
        <div class="calendar-connect-header">
          <p class="calendar-connect-header-title">{{ modalHeader }}</p>
          <Button
            icon="close"
            type="grey"
            size="xs"
            @click="() => (step ? cancelTemplateSelect : close)()"
          />
        </div>
        <div v-if="!step" class="calendar-connect-content">
          <p class="calendar-connect-content-header">
            Select which of your calendars we should use to automatically
            prepare meetings. These meetings will be visible here and a summary
            with generated assets will be sent to your email daily.
          </p>
          <div class="calendar-connect-content-list">
            <div v-if="loading" class="calendar-connect-content-list-loading">
              <b-loading active :is-full-page="false" />
            </div>
            <div
              v-for="calendar in calendars"
              :key="calendar.uuid"
              class="calendar-connect-content-list-item"
            >
              {{ calendar.name }}
              <Button
                :text="calendar.sync ? 'Disconnect' : 'Sync'"
                :type="calendar.sync ? 'white' : 'primary'"
                :loading="calendarSyncLoading.includes(calendar.uuid)"
                @click="() => syncCalendar(calendar)"
              />
            </div>
          </div>
          <Button
            v-if="!loading && connectedCalendars.length"
            text="Manage generated assets"
            type="white"
            :full-width="true"
            :loading="templatesSaveLoading"
            @click="selectTemplates"
          >
            <template #right>
              <Tag
                :text="`${selectedTemplates.length} / 3`"
                size="xxs"
                type="light-solid"
              />
            </template>
          </Button>
        </div>
        <div v-else class="calendar-connect-templates">
          <MeetingOutputsSelect
            :force-selected="selectedTemplates.map(t => t.uuid)"
            :hide-settings="true"
            :max-selected="3"
            @select="editSelectedTemplates"
          />
        </div>
        <div v-if="step" class="calendar-connect-footer">
          <div class="calendar-connect-footer-left">
            <p class="calendar-connect-footer-left-count">
              {{ tempSelection.length }} output{{
                tempSelection.length === 1 ? '' : 's'
              }}
              selected
            </p>
            <p class="calendar-connect-footer-left-max">(max. 3)</p>
          </div>
          <div class="calendar-connect-footer-btns">
            <Button text="Close" type="white" @click="cancelTemplateSelect" />
            <Button text="Save" type="primary" @click="saveTemplates" />
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Tag from '@c/library/Tag.vue'
import Button from '@c/library/Button.vue'
import MeetingOutputsSelect from '@/views-v2/meeting/outputs/MeetingOutputsSelect.vue'
import { userCalendarIntegrations } from '@c/integrations'
import { mapGetters, mapActions } from 'vuex'
import { buildOauthUrl, getNonce } from '@/util'

export default {
  name: 'CalendarConnect',
  components: { Tag, Button, MeetingOutputsSelect },
  data: () => ({
    modalVisible: false,
    calendars: [],
    loading: false,
    step: 0,
    calendarSyncLoading: [],
    selectedTemplates: [],
    tempSelection: [],
    templatesSaveLoading: false
  }),
  computed: {
    ...mapGetters([
      'templates',
      'currentUser',
      'currentWorkspace',
      'currentWorkspaceMember',
      'workspaceMemberIntegrations',
      'workspaceMemberIntegrationsLoaded'
    ]),
    currentUserDomain() {
      return this.$umodel.email(this.currentUser).split('@')[1]
    },
    integrationKey() {
      const providers = {
        google_drive: 'google_calendar',
        sharepoint: 'microsoft_outlook_calendar'
      }
      return Object.keys(providers).reduce(
        (acc, curr) =>
          (this.currentWorkspace?.integrations || []).includes(curr)
            ? acc || providers[curr]
            : acc,
        ''
      )
    },
    integrationObject() {
      return userCalendarIntegrations[this.integrationKey]
    },
    connected() {
      return (this.workspaceMemberIntegrations || []).find(
        ui => ui.type === this.integrationKey
      )
    },
    triggerText() {
      return this.connected ? 'Manage calendar sync' : 'Connect calendar'
    },
    triggerFunction() {
      return this.connected ? this.openModal : this.connectIntegration
    },
    modalHeader() {
      return this.step
        ? `Assets for ${this.integrationObject.title} sync`
        : `Sync ${this.integrationObject.title} calendars`
    },
    connectedCalendars() {
      return this.calendars.filter(c => c.sync)
    }
  },
  methods: {
    ...mapActions([
      'getTemplates',
      'getWorkspaceMemberIntegrations',
      'getWorkspaceMemberIntegrationFiles',
      'setCalendarSync'
    ]),
    connectIntegration() {
      if (this.connected) {
        this.$emit('connected')
        return
      }
      const nonce = getNonce(25)
      localStorage.setItem(
        'integrationWorkspace',
        this.$route.params.workspace_id
      )
      localStorage.setItem('integrationNonce', nonce)
      const url = buildOauthUrl(this.integrationObject.oauthBaseURL, {
        ...this.integrationObject.oauthQueryParams,
        state: `${nonce}%member`,
        hd: this.currentUserDomain
      })
      window.open(`${url}`, '_blank')
      let bc = new BroadcastChannel('uman_integration_auth')
      bc.onmessage = status => this.validateIntegrationAuth(status)
    },
    openModal() {
      this.modalVisible = true
      this.loadCalendars()
    },
    close() {
      this.modalVisible = false
    },
    async validateIntegrationAuth(status) {
      if (status && status.data === 'done') {
        this.$toast.success(
          `${this.integrationObject.title} authenticated`,
          `We successfully connected your ${this.integrationObject.title} account to uman. You can now select calendars to synchronize.`
        )
        await this.getWorkspaceMemberIntegrations({
          workspace_id: this.currentWorkspace.uuid,
          member_id: this.currentWorkspaceMember.uuid
        })
        this.$emit('connected')
        this.openModal()
      } else if (status) {
        this.$toast.danger(
          'An error occurred',
          status.data === 'error'
            ? 'We could not connect to your calendar. Please try again or contact support.'
            : status.data
        )
        this.$emit('error', status)
      }
    },
    async loadCalendars() {
      try {
        this.loading = true
        this.calendars = []
        this.selectedTemplates = []
        const res = await Promise.all([
          await this.getWorkspaceMemberIntegrationFiles({
            workspace_id: this.$route.params.workspace_id,
            member_id: this.currentWorkspaceMember.uuid,
            integration_id: this.connected.id,
            object_name: 'calendars'
          }),
          this.getTemplates({
            workspace_id: this.$route.params.workspace_id
          })
        ])
        this.calendars = res[0]
        const synced = this.calendars.find(c => c.sync)
        if (synced) {
          this.selectedTemplates = res[1].filter(t =>
            synced.config?.tool_ids?.includes(t.uuid)
          )
        } else {
          this.selectedTemplates = res[1].filter(t => !t.dynamic)
        }
      } catch (e) {
        this.$console.debug('Error loading calendars', e)
        this.$toast.error(e, 'loading calendars')
      } finally {
        this.loading = false
      }
    },
    async syncCalendar(calendar) {
      try {
        this.calendarSyncLoading = [...this.calendarSyncLoading, calendar.uuid]
        await this.setCalendarSync({
          workspace_id: this.$route.params.workspace_id,
          member_id: this.currentWorkspaceMember.uuid,
          integration_id: this.connected.id,
          calendar_id: calendar.uuid,
          sync: String(!calendar.sync),
          tool_ids: this.selectedTemplates.map(t => t.uuid)
        })
        this.calendars = this.calendars.map(c =>
          c.uuid === calendar.uuid ? { ...c, sync: !c.sync } : c
        )
      } catch (e) {
        this.$toast.error(e, 'syncing calendar')
      } finally {
        this.calendarSyncLoading = this.calendarSyncLoading.filter(
          l => l !== calendar.uuid
        )
      }
    },
    selectTemplates() {
      this.step++
      this.tempSelection = [...this.selectedTemplates]
    },
    editSelectedTemplates(selected) {
      this.tempSelection = selected
    },
    cancelTemplateSelect() {
      this.step = 0
    },
    async saveTemplates() {
      let res = []
      try {
        this.selectedTemplates = [...this.tempSelection]
        this.step = 0
        this.templatesSaveLoading = true
        res = await Promise.all(
          this.calendars
            .filter(c => c.sync)
            .map(c =>
              this.setCalendarSync({
                workspace_id: this.$route.params.workspace_id,
                member_id: this.currentWorkspaceMember.uuid,
                integration_id: this.connected.id,
                calendar_id: c.uuid,
                sync: 'true',
                tool_ids: this.selectedTemplates.map(t => t.uuid)
              }).catch(() => ({ error: c }))
            )
        )
      } finally {
        this.templatesSaveLoading = false
      }
      if (res.some(r => r.error)) {
        this.$toast.danger(
          'Error saving templates for some calendars',
          `We could not save your selected templates to these calendars: ${res
            .filter(r => r.error)
            .map(r => r.error.name)
            .join(', ')}`
        )
      } else {
        this.$toast.success(
          'Templates saved',
          'We successfully saved your selected templates to all calendars.'
        )
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.calendar-connect {
  &-trigger {
    &-img {
      height: 1.2rem;
    }
  }

  background: white;
  border-radius: 6px;

  &.restricted {
    width: min(50rem, 95vw);
  }

  &-header {
    display: flex;
    justify-content: space-between;
    padding: 1.5rem;
    border-bottom: 1px solid rgba(#000, 0.08);

    &-title {
      font-size: 1.25rem;
      font-weight: 600;
    }
  }

  &-content {
    padding: 1.5rem;

    &-header {
      margin-bottom: 1rem;
    }

    &-list {
      display: flex;
      flex-flow: column nowrap;
      max-height: 40vh;
      overflow: auto;

      &-loading {
        position: relative;
        min-height: 5rem;
      }

      &-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 1rem;

        &:not(:last-child) {
          border-bottom: 1px solid rgba(#000, 0.08);
        }

        &:hover {
          background: rgba(#000, 0.04);
        }
      }
    }
  }

  &-templates {
    height: 70vh;
    max-height: 70vh;
    overflow-y: auto;
  }

  &-footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    gap: 1rem;
    padding: 1.5rem;
    border-top: 1px solid rgba(#000, 0.08);

    &-left {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;

      &-count {
        font-size: 1.1rem;
        font-weight: 600;
      }

      &-max {
        color: #60666b;
      }
    }

    &-btns {
      display: flex;
      flex-flow: row nowrap;
      gap: 1rem;
    }
  }
}

::v-deep .modal-content {
  width: unset !important;
  max-width: unset !important;
}
</style>
