<template>
  <b-modal active @close="close">
    <div class="meeting-title">
      <div class="meeting-title-header">
        <p class="meeting-title-header-title">Meeting title</p>
        <Button icon="close" size="xs" type="grey" @click="close" />
      </div>
      <div ref="meetingtitlecontent" class="meeting-title-content">
        <TextInput v-model="title" />
      </div>
      <div class="meeting-title-footer">
        <Button text="Cancel" type="white" @click="close" />
        <Button
          text="Save"
          :disabled="!titleChanged"
          :loading="loading"
          @click="submit"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import Button from '@c/library/Button.vue'
import TextInput from '@c/library/TextInput.vue'
import { editMeeting } from '@/services/meetingService'

export default {
  name: 'MeetingTitleModal',
  components: {
    Button,
    TextInput
  },
  props: {
    meeting: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    title: '',
    loading: false
  }),
  computed: {
    titleChanged() {
      return this.title !== this.meeting.title
    }
  },
  created() {
    this.title = this.meeting.title
  },
  mounted() {
    this.$refs.meetingtitlecontent.querySelector('input').focus()
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async submit() {
      try {
        this.loading = true
        const res = await editMeeting({
          workspace_id: this.$route.params.workspace_id,
          story_id: this.meeting.uuid,
          title: this.title
        })
        this.$emit('input', res)
        this.close()
      } catch (e) {
        this.$console.debug('Error editing meeting title', e)
        this.$toast.error(e, 'editing meeting title')
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.meeting-title {
  background: white;
  border-radius: 8px;
  width: min(30rem, 90vw);

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    border-bottom: 1px solid rgba(#000, 0.08);

    &-title {
      font-size: 1.25rem;
      font-weight: 600;
    }
  }

  &-content {
    padding: 1.5rem;
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    padding: 1.5rem;
    border-top: 1px solid rgba(#000, 0.08);
  }
}

::v-deep .modal-content {
  width: unset !important;
  max-width: unset !important;
}
</style>
