var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "meeting", class: { expanded: _vm.expanded } },
    [
      _c(
        "div",
        {
          staticClass: "meeting-info",
          on: {
            click: function() {
              return (_vm.expanded = !_vm.expanded)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "meeting-info-header" },
            [
              _c("Avatar", {
                staticClass: "meeting-info-header-img",
                attrs: {
                  user: { avatar: _vm.image },
                  "fallback-icon": "company"
                }
              }),
              _c("p", { staticClass: "meeting-info-header-name" }, [
                _vm._v(_vm._s(_vm.meeting.title))
              ]),
              _c("p", { staticClass: "meeting-info-header-dot" }, [
                _vm._v("•")
              ]),
              _c("p", { staticClass: "meeting-info-header-date" }, [
                _vm._v(_vm._s(_vm.date))
              ]),
              _vm.integrationIcon
                ? _c(
                    "b-tooltip",
                    {
                      attrs: {
                        label: "From " + _vm.integrationCalendarName,
                        type: "is-dark",
                        position: "is-right"
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "meeting-info-header-icon",
                        attrs: { src: _vm.integrationIcon, alt: "" }
                      })
                    ]
                  )
                : _vm._e(),
              _c("Button", {
                staticClass: "meeting-info-header-btn",
                attrs: { text: "Generate assets", size: "xs", icon: "ai" },
                nativeOn: {
                  click: function($event) {
                    $event.stopPropagation()
                    return function() {
                      return _vm.$emit("generate", _vm.meeting)
                    }.apply(null, arguments)
                  }
                }
              }),
              !_vm.removeLoading
                ? _c("Dropdown", {
                    attrs: { items: _vm.dropdownItems, "position-fixed": true },
                    nativeOn: {
                      click: function($event) {
                        $event.stopPropagation()
                      }
                    }
                  })
                : _c("img", {
                    staticClass: "meeting-info-header-loading",
                    attrs: {
                      src: require("@/assets/icons/spinner.svg"),
                      alt: ""
                    }
                  }),
              _c("img", {
                staticClass: "meeting-info-chevron",
                class: { expanded: _vm.expanded },
                attrs: {
                  src: require("@/assets/icons/chevron-down-medium.svg"),
                  alt: ""
                }
              })
            ],
            1
          ),
          _vm.meeting.people.length || _vm.tags.length
            ? _c(
                "div",
                { staticClass: "meeting-info-row" },
                [
                  _vm._l(_vm.meeting.people, function(person) {
                    return _c(
                      "b-tooltip",
                      {
                        key:
                          "meetingcard-" + (person.linkedin_url || person.role),
                        attrs: {
                          label: _vm.$umodel.full_name(person),
                          "append-to-body": true,
                          type: "is-dark"
                        }
                      },
                      [
                        _c("Avatar", {
                          attrs: {
                            user: person,
                            size: "xs",
                            "fallback-icon": "user"
                          }
                        })
                      ],
                      1
                    )
                  }),
                  _vm.meeting.people.length && _vm.tags.length
                    ? _c("div", { staticClass: "meeting-info-row-separator" })
                    : _vm._e(),
                  _vm._l(_vm.tags, function(tag, idx) {
                    return _c("Tag", {
                      key: "meetingcard-tag-" + idx,
                      attrs: {
                        text: tag,
                        rounded: false,
                        type: "grey",
                        size: "xxs"
                      }
                    })
                  })
                ],
                2
              )
            : _vm._e()
        ]
      ),
      _c("div", { staticClass: "meeting-assets-wrapper" }, [
        _c("div", { staticClass: "meeting-assets" }, [
          _c(
            "div",
            { staticClass: "meeting-assets-header" },
            [
              _c("p", { staticClass: "meeting-assets-header-title" }, [
                _vm._v("Meeting assets")
              ]),
              _vm.assets.length
                ? _c("Tag", {
                    attrs: {
                      text: "" + _vm.assets.length,
                      type: "light-solid",
                      size: "xxs"
                    }
                  })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "meeting-assets-header-buttons" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: _vm.outputsRoute() } },
                    [
                      _c("Button", {
                        attrs: {
                          text: "Go to assets",
                          size: "xs",
                          disabled: !_vm.assets.length,
                          icon: "chevron-right-medium"
                        },
                        on: {
                          click: function() {
                            return _vm.$emit("outputs", _vm.meeting)
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.assets.length
            ? _c(
                "div",
                { staticClass: "meeting-assets-list" },
                _vm._l(_vm.assets, function(asset) {
                  return _c(
                    "router-link",
                    {
                      key: "meetingcard-asset-" + asset.uuid,
                      attrs: { to: _vm.outputsRoute(asset) }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "meeting-assets-list-item-wrapper" },
                        [
                          _c("TemplateCard", {
                            staticClass: "meeting-assets-list-item",
                            attrs: { template: asset }
                          }),
                          _c(
                            "div",
                            { staticClass: "meeting-assets-list-item-scrim" },
                            [
                              _vm._v(" View generated asset "),
                              _c("img", {
                                staticClass:
                                  "meeting-assets-list-item-scrim-arrow",
                                attrs: {
                                  src: require("@/assets/icons/arrow-right.svg"),
                                  alt: ""
                                }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  )
                }),
                1
              )
            : _c("div", { staticClass: "meeting-assets-list-empty" }, [
                _vm._v(" No assets generated yet for this meeting ")
              ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }