var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-modal", { attrs: { active: "" }, on: { close: _vm.close } }, [
    _c("div", { staticClass: "meeting-title" }, [
      _c(
        "div",
        { staticClass: "meeting-title-header" },
        [
          _c("p", { staticClass: "meeting-title-header-title" }, [
            _vm._v("Meeting title")
          ]),
          _c("Button", {
            attrs: { icon: "close", size: "xs", type: "grey" },
            on: { click: _vm.close }
          })
        ],
        1
      ),
      _c(
        "div",
        { ref: "meetingtitlecontent", staticClass: "meeting-title-content" },
        [
          _c("TextInput", {
            model: {
              value: _vm.title,
              callback: function($$v) {
                _vm.title = $$v
              },
              expression: "title"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "meeting-title-footer" },
        [
          _c("Button", {
            attrs: { text: "Cancel", type: "white" },
            on: { click: _vm.close }
          }),
          _c("Button", {
            attrs: {
              text: "Save",
              disabled: !_vm.titleChanged,
              loading: _vm.loading
            },
            on: { click: _vm.submit }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }