var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "meetings" },
    [
      _c("div", { staticClass: "meetings-header" }, [
        _c(
          "div",
          { staticClass: "meetings-header-btns" },
          [
            _vm.canWriteCalendars ? _c("CalendarConnect") : _vm._e(),
            _c("Button", {
              attrs: {
                text: "Prepare new meeting",
                type: "primary",
                icon: "plus-medium"
              },
              on: { click: _vm.createNewMeeting }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "infinite-scroll",
              rawName: "v-infinite-scroll",
              value: _vm.handleInfiniteScroll,
              expression: "handleInfiniteScroll"
            }
          ],
          ref: "meetingswrapper",
          staticClass: "meetings-list",
          style: _vm.maxHeightStyle,
          attrs: {
            "infinite-scroll-disabled": _vm.consumptionLoading || _vm.isDone,
            "infinite-scroll-distance": "100",
            "infinite-scroll-throttle-delay": "500"
          }
        },
        [
          _vm._l(_vm.meetings, function(meeting) {
            return _c("MeetingCard", {
              key: meeting.uuid,
              attrs: {
                meeting: meeting,
                "remove-loading": _vm.removeLoading.includes(meeting.uuid)
              },
              on: {
                remove: _vm.deleteMeeting,
                rename: function() {
                  return _vm.showRenameModal(meeting)
                },
                generate: _vm.showOutputsModal
              }
            })
          }),
          _c(
            "div",
            {
              staticClass: "meetings-list-end",
              class: {
                padded: _vm.meetings.length,
                loading: _vm.consumptionLoading
              }
            },
            [
              _vm.consumptionLoading
                ? _c("b-loading", {
                    attrs: { active: "", "is-full-page": false }
                  })
                : _vm.isDone
                ? _c(
                    "div",
                    {
                      staticClass: "meetings-list-end-msg",
                      attrs: { div: "" }
                    },
                    [
                      !_vm.meetings.length
                        ? _c("img", {
                            attrs: {
                              src: require("@/assets/icons/calendar.svg"),
                              alt: ""
                            }
                          })
                        : _vm._e(),
                      _c("p", { staticClass: "meetings-list-end-msg-header" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.meetings.length
                                ? "End of the road, these are all your meetings"
                                : "No meetings have been created yet"
                            ) +
                            " "
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "meetings-list-end-msg-sub" },
                        [
                          _vm._v(" Create a new meeting by clicking the "),
                          _c("Tag", {
                            staticClass: "meetings-list-end-msg-sub-tag",
                            attrs: {
                              type: "white",
                              size: "xxs",
                              text: "Prepare new meeting",
                              icon: "plus-medium"
                            }
                          }),
                          _vm._v(" button above ")
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        2
      ),
      _vm.showCreateMeetingModal
        ? _c("EditMeeting", {
            attrs: { visible: _vm.showCreateMeetingModal },
            on: {
              close: function() {
                return (_vm.showCreateMeetingModal = false)
              },
              created: _vm.handleInitMeeting
            }
          })
        : _vm._e(),
      _vm.showMeetingOutputsModal
        ? _c("EditMeeting", {
            attrs: {
              visible: _vm.showMeetingOutputsModal,
              mode: "edit",
              meeting: _vm.selectedMeeting
            },
            on: { close: _vm.closeOutputsModal, edit: _vm.handleEditMeeting }
          })
        : _vm._e(),
      _vm.showRenameMeetingModal
        ? _c("MeetingTitleModal", {
            attrs: { meeting: _vm.selectedMeeting },
            on: { input: _vm.editMeetingName, close: _vm.closeRenameModal }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }