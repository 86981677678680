<template>
  <div class="meeting" :class="{ expanded }">
    <div class="meeting-info" @click="() => (expanded = !expanded)">
      <div class="meeting-info-header">
        <Avatar
          :user="{ avatar: image }"
          fallback-icon="company"
          class="meeting-info-header-img"
        />
        <p class="meeting-info-header-name">{{ meeting.title }}</p>
        <p class="meeting-info-header-dot">&bull;</p>
        <p class="meeting-info-header-date">{{ date }}</p>
        <b-tooltip
          v-if="integrationIcon"
          :label="`From ${integrationCalendarName}`"
          type="is-dark"
          position="is-right"
        >
          <img :src="integrationIcon" alt="" class="meeting-info-header-icon" />
        </b-tooltip>
        <Button
          text="Generate assets"
          size="xs"
          icon="ai"
          class="meeting-info-header-btn"
          @click.native.stop="() => $emit('generate', meeting)"
        />
        <Dropdown
          v-if="!removeLoading"
          :items="dropdownItems"
          :position-fixed="true"
          @click.native.stop
        />
        <img
          v-else
          src="@/assets/icons/spinner.svg"
          alt=""
          class="meeting-info-header-loading"
        />
        <img
          src="@/assets/icons/chevron-down-medium.svg"
          alt=""
          class="meeting-info-chevron"
          :class="{ expanded }"
        />
      </div>
      <div v-if="meeting.people.length || tags.length" class="meeting-info-row">
        <b-tooltip
          v-for="person in meeting.people"
          :key="`meetingcard-${person.linkedin_url || person.role}`"
          :label="$umodel.full_name(person)"
          :append-to-body="true"
          type="is-dark"
        >
          <Avatar :user="person" size="xs" fallback-icon="user" />
        </b-tooltip>
        <div
          v-if="meeting.people.length && tags.length"
          class="meeting-info-row-separator"
        ></div>
        <Tag
          v-for="(tag, idx) in tags"
          :key="`meetingcard-tag-${idx}`"
          :text="tag"
          :rounded="false"
          type="grey"
          size="xxs"
        />
      </div>
    </div>
    <div class="meeting-assets-wrapper">
      <div class="meeting-assets">
        <div class="meeting-assets-header">
          <p class="meeting-assets-header-title">Meeting assets</p>
          <Tag
            v-if="assets.length"
            :text="`${assets.length}`"
            type="light-solid"
            size="xxs"
          />
          <div class="meeting-assets-header-buttons">
            <router-link :to="outputsRoute()">
              <Button
                text="Go to assets"
                size="xs"
                :disabled="!assets.length"
                icon="chevron-right-medium"
                @click="() => $emit('outputs', meeting)"
              />
            </router-link>
          </div>
        </div>
        <div v-if="assets.length" class="meeting-assets-list">
          <router-link
            v-for="asset in assets"
            :key="`meetingcard-asset-${asset.uuid}`"
            :to="outputsRoute(asset)"
          >
            <div class="meeting-assets-list-item-wrapper">
              <TemplateCard
                :template="asset"
                class="meeting-assets-list-item"
              />
              <div class="meeting-assets-list-item-scrim">
                View generated asset
                <img
                  src="@/assets/icons/arrow-right.svg"
                  alt=""
                  class="meeting-assets-list-item-scrim-arrow"
                />
              </div>
            </div>
          </router-link>
        </div>
        <div v-else class="meeting-assets-list-empty">
          No assets generated yet for this meeting
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '@c/library/Avatar.vue'
import Button from '@c/library/Button.vue'
import Tag from '@c/library/Tag.vue'
import { formatDate } from '@/util'
import Dropdown from '@c/library/Dropdown.vue'
import TemplateCard from '../templates/TemplateCard.vue'

export default {
  name: 'MeetingCard',
  components: { Avatar, Button, Tag, Dropdown, TemplateCard },
  props: {
    meeting: {
      type: Object,
      required: true
    },
    removeLoading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    expanded: false
  }),
  computed: {
    image() {
      return (
        this.meeting.account?.organisation?.icon ||
        this.meeting.account?.organisation?.logo ||
        ''
      )
    },
    date() {
      return this.meeting?.date_updated
        ? formatDate(this.meeting?.date_updated)
        : this.meeting?.date_created
        ? formatDate(this.meeting?.date_created)
        : ''
    },
    assets() {
      return (this.meeting.outputs || []).map(o => ({
        ...o.tool,
        ...o,
        name: o.name || o.tool.name
      }))
    },
    tags() {
      return [
        ...(this.meeting?.notes?.length
          ? [
              `${this.meeting?.notes.length} note${
                this.meeting?.notes.length === 1 ? '' : 's'
              }`
            ]
          : []),
        ...(this.meeting.offerings?.length
          ? [
              `${this.meeting.offerings.length} offering${
                this.meeting.offerings.length === 1 ? '' : 's'
              }`
            ]
          : []),
        ...(this.meeting.references?.length
          ? [
              `${this.meeting.references.length} case${
                this.meeting.references.length === 1 ? '' : 's'
              }`
            ]
          : []),
        ...(this.meeting.inspirations?.length
          ? [
              `${this.meeting.inspirations.length} inspiration${
                this.meeting.inspirations.length === 1 ? '' : 's'
              }`
            ]
          : []),
        ...(this.assets?.length
          ? [
              `${this.assets.length} asset${
                this.assets.length === 1 ? '' : 's'
              }`
            ]
          : [])
      ]
    },
    dropdownItems() {
      return [
        {
          text: 'Rename meeting',
          icon: 'edit',
          callback: () => this.$emit('rename', this.meeting.uuid)
        },
        {
          text: 'Delete meeting',
          icon: 'bin',
          filter: 'red',
          callback: () => this.$emit('remove', this.meeting.uuid)
        }
      ]
    },
    integrationIcon() {
      const source = this.meeting?.integration_calendar?.integration?.type || ''
      if (!this.meeting.integration_calendar || !source) return ''
      return require(`@/assets/logo/${
        {
          google_calendar: 'google_calendar',
          microsoft_outlook_calendar: 'outlook_calendar'
        }[source]
      }.svg`)
    },
    integrationCalendarName() {
      return this.meeting?.integration_calendar?.name || ''
    }
  },
  methods: {
    outputsRoute(output = undefined) {
      return {
        name: 'meeting-outputs',
        params: {
          workspace_id: this.$route.params.workspace_id,
          meeting_id: this.meeting.uuid
        },
        query: {
          ...(output ? { selected: output.version_id } : {})
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.meeting {
  border-radius: 8px;
  border: 1px solid rgba(#000, 0.08);
  box-shadow: 0px 3px 5px 0px rgba(#000, 0.08);
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: 0px 3px 5px 3px rgba(#000, 0.08);
  }

  &.expanded {
    & .meeting-info {
      border-radius: 0;

      &-chevron {
        transform: rotate(180deg);
      }
    }

    & .meeting-assets-wrapper {
      max-height: 100vh;
    }
  }

  &-info {
    display: flex;
    flex-flow: column nowrap;
    gap: 0.75rem;
    padding: 1.5rem;
    cursor: pointer;
    border-bottom: 1px solid rgba(#000, 0.08);
    border-radius: 8px;
    transition: border-radius 0.2s ease-in-out;
    overflow: hidden;

    &-chevron {
      height: 1.2rem;
      transition: transform 0.2s ease;
    }

    &-header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.75rem;
      user-select: none;

      &-img {
        border-radius: 999rem;
        box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
      }

      &-name {
        font-size: 1.15rem;
        font-weight: 600;
        user-select: auto;
      }

      &-dot {
        color: #8f9399;
      }

      &-icon {
        height: 1.5rem;
      }

      &-date {
        color: #8f9399;
      }

      &-btn {
        margin-left: auto;
      }

      &-loading {
        height: 1.2rem;
        animation: spin 1s linear infinite;
      }
    }

    &-row {
      display: flex;
      flex-flow: row wrap;
      gap: 0.5rem;
      align-items: center;

      &-separator {
        width: 0.25rem;
      }
    }
  }

  &-assets {
    max-height: 100vh;
    overflow: auto;

    &-wrapper {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.5s ease-in-out;
    }

    &-header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;
      padding: 0.75rem 1.5rem;

      &-title {
        font-weight: 700;
        padding: 0.35rem 0;
      }

      &-buttons {
        margin-left: auto;
        display: flex;
        flex-flow: row nowrap;
        gap: 0.5rem;
      }
    }

    &-list {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
      gap: 1rem;
      padding: 0 1.5rem 1.5rem;

      &-item {
        height: 100%;

        &-wrapper {
          cursor: pointer;
          position: relative;
          border-radius: 12px;
          height: 100%;

          &:hover {
            box-shadow: 0px 3px 5px 0px rgba(#000, 0.08);

            & .meeting-assets-list-item-scrim {
              opacity: 1;
            }
          }
        }

        &-scrim {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-end;
          padding: 0.5rem;
          gap: 0.25rem;
          background: linear-gradient(to right, rgba(#fff, 0.2), rgba(#fff, 1));
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border-radius: 4px;
          opacity: 0;
          transition: opacity 0.5s ease;
          color: $primary;
          border-radius: 12px;
          font-weight: 600;

          &-arrow {
            height: 1.5rem;
            filter: brightness(0) saturate(100%) invert(25%) sepia(95%)
              saturate(2915%) hue-rotate(217deg) brightness(97%) contrast(103%);
          }
        }
      }

      &-empty {
        color: #60666b;
        padding: 1rem 1.5rem;
        border-radius: 4px;
        border: 1px solid rgba(#000, 0.08);
        background: rgba(#000, 0.02);
        margin: 0 1.5rem 1.5rem;
      }
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
