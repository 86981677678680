<template>
  <div class="meetings">
    <div class="meetings-header">
      <div class="meetings-header-btns">
        <CalendarConnect v-if="canWriteCalendars" />
        <Button
          text="Prepare new meeting"
          type="primary"
          icon="plus-medium"
          @click="createNewMeeting"
        />
      </div>
    </div>
    <div
      ref="meetingswrapper"
      v-infinite-scroll="handleInfiniteScroll"
      class="meetings-list"
      :style="maxHeightStyle"
      :infinite-scroll-disabled="consumptionLoading || isDone"
      infinite-scroll-distance="100"
      infinite-scroll-throttle-delay="500"
    >
      <MeetingCard
        v-for="meeting in meetings"
        :key="meeting.uuid"
        :meeting="meeting"
        :remove-loading="removeLoading.includes(meeting.uuid)"
        @remove="deleteMeeting"
        @rename="() => showRenameModal(meeting)"
        @generate="showOutputsModal"
      />
      <div
        class="meetings-list-end"
        :class="{ padded: meetings.length, loading: consumptionLoading }"
      >
        <b-loading v-if="consumptionLoading" active :is-full-page="false" />
        <div v-else-if="isDone" div class="meetings-list-end-msg">
          <img
            v-if="!meetings.length"
            src="@/assets/icons/calendar.svg"
            alt=""
          />
          <p class="meetings-list-end-msg-header">
            {{
              meetings.length
                ? 'End of the road, these are all your meetings'
                : 'No meetings have been created yet'
            }}
          </p>
          <div class="meetings-list-end-msg-sub">
            Create a new meeting by clicking the
            <Tag
              type="white"
              size="xxs"
              text="Prepare new meeting"
              icon="plus-medium"
              class="meetings-list-end-msg-sub-tag"
            />
            button above
          </div>
        </div>
      </div>
    </div>
    <EditMeeting
      v-if="showCreateMeetingModal"
      :visible="showCreateMeetingModal"
      @close="() => (showCreateMeetingModal = false)"
      @created="handleInitMeeting"
    />
    <EditMeeting
      v-if="showMeetingOutputsModal"
      :visible="showMeetingOutputsModal"
      mode="edit"
      :meeting="selectedMeeting"
      @close="closeOutputsModal"
      @edit="handleEditMeeting"
    />
    <MeetingTitleModal
      v-if="showRenameMeetingModal"
      :meeting="selectedMeeting"
      @input="editMeetingName"
      @close="closeRenameModal"
    />
  </div>
</template>

<script>
import { getMeetingsPaginator, removeMeeting } from '@/services/meetingService'
import { mapActions, mapGetters } from 'vuex'
import { PaginatorConsumer } from '@/mixins/PaginatorConsumer'
import { MaxHeightMixin } from '@/mixins/MaxHeightMixin'
import Tag from '@c/library/Tag.vue'
import Button from '@c/library/Button.vue'
import MeetingCard from './meeting/MeetingCard.vue'
import EditMeeting from './meeting/EditMeeting.vue'
import MeetingTitleModal from './meeting/MeetingTitleModal.vue'
import CalendarConnect from './integrations/CalendarConnect.vue'

export default {
  name: 'Meetings',
  components: {
    Tag,
    Button,
    MeetingCard,
    EditMeeting,
    CalendarConnect,
    MeetingTitleModal
  },
  mixins: [PaginatorConsumer, MaxHeightMixin],
  data: () => ({
    sidebarCollapsed: true,
    showCreateMeetingModal: false,
    showMeetingOutputsModal: false,
    showRenameMeetingModal: false,
    selectedMeeting: undefined,
    removeLoading: []
  }),
  computed: {
    ...mapGetters(['currentWorkspace', 'canWriteStories', 'canWriteCalendars']),
    meetings() {
      return this.paginatorData
    }
  },
  created() {
    if (
      !this.currentWorkspace ||
      this.currentWorkspace.uuid !== this.$route.params.workspace_id
    ) {
      this.setCurrentWorkspace(this.$route.params.workspace_id)
    }
    if (!this.canWriteStories) {
      this.$router.push({ name: 'home' })
    }
    if (this.$route.query.create) {
      this.createNewMeeting()
      this.$router.replace({ query: {} })
    }
  },
  methods: {
    ...mapActions(['setCurrentWorkspace']),
    getMaxHeightElement() {
      return this.$refs.meetingswrapper
    },
    handleInfiniteScroll() {
      if (this.consumptionLoading || this.isDone) return
      this.loadPage()
    },
    async getPaginator() {
      return await getMeetingsPaginator({
        workspace_id: this.$route.params.workspace_id,
        extended: true
      })
    },
    createNewMeeting() {
      this.showCreateMeetingModal = true
    },
    handleInitMeeting({ meeting, templates, settings }) {
      this.paginatorData = [meeting, ...this.paginatorData]
      this.showCreateMeetingModal = false
      this.handleGenerate(meeting, templates, settings)
    },
    handleEditMeeting({ meeting, templates, settings, changed }) {
      this.paginatorData = this.paginatorData.map((m) =>
        m.uuid === meeting.uuid ? meeting : m
      )
      this.closeOutputsModal()
      this.handleGenerate(meeting, templates, settings, changed)
    },
    showOutputsModal(meeting) {
      this.selectedMeeting = meeting
      this.showMeetingOutputsModal = true
    },
    closeOutputsModal() {
      this.selectedMeeting = undefined
      this.showMeetingOutputsModal = false
    },
    showRenameModal(meeting) {
      this.selectedMeeting = meeting
      this.showRenameMeetingModal = true
    },
    closeRenameModal() {
      this.selectedMeeting = undefined
      this.showRenameMeetingModal = false
    },
    editMeetingName(meeting) {
      this.paginatorData = this.paginatorData.map((m) =>
        m.uuid === meeting.uuid ? meeting : m
      )
    },
    async deleteMeeting(uuid) {
      try {
        this.removeLoading = [...this.removeLoading, uuid]
        await removeMeeting({
          workspace_id: this.$route.params.workspace_id,
          meeting_id: uuid
        })
        this.paginatorData = this.paginatorData.filter((m) => m.uuid !== uuid)
      } catch (e) {
        if (e === 'Not found.') {
          this.paginatorData = this.paginatorData.filter((m) => m.uuid !== uuid)
          return
        }
        this.$console.debug('Something went wrong when deleting a meeting', e)
        this.$toast.error(e, 'deleting this meeting')
      } finally {
        this.removeLoading = this.removeLoading.filter((l) => l !== uuid)
      }
    },
    handleGenerate(meeting, templates, props, changed = false) {
      localStorage.setItem('meetingOutputTemplates', JSON.stringify(templates))
      if (changed) localStorage.setItem('meetingOutputStoryChange', 'true')
      let settings = localStorage.getItem('meetingOutputProps')
      settings = settings ? JSON.parse(settings) : {}
      localStorage.setItem(
        'meetingOutputProps',
        JSON.stringify({ ...settings, ...props })
      )
      this.$router.push({
        name: 'meeting-outputs',
        params: {
          workspace_id: this.$route.params.workspace_id,
          meeting_id: meeting.uuid
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.meetings {
  overflow: auto;

  &-header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1rem;
    padding: 2.5rem;

    &-btns {
      display: flex;
      flex-flow: row nowrap;
      gap: 0.5rem;
      margin-left: auto;
    }
  }

  &-list {
    display: flex;
    flex-flow: column;
    gap: 1.5rem;
    padding: 0 2.5rem 2.5rem;
    overflow: auto;

    &-end {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      gap: 1rem;
      position: relative;

      &.padded {
        margin-top: 1rem;
      }

      &.loading {
        min-height: 5rem;
      }

      &-msg {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        gap: 0.5rem;
        text-align: center;

        &-header {
          font-size: 1.25rem;
          font-weight: 600;
        }

        &-sub {
          color: #8f9399;
          display: inline;

          &-tag {
            display: inline-flex;
          }
        }
      }
    }

    &-loading {
      height: 2.5rem;
      animation: spin 1s linear infinite;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
